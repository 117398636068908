import React, { useRef, useState } from 'react';
import { NavLink, useNavigate } from "react-router-dom";
import gl_logoa from './Img/gl_logoa.png'
import user from './Img/user.png'
import { toast } from 'react-toastify';
import { Modal, Button } from 'react-bootstrap';
const Navbar = () => {
  const Auth = localStorage.getItem('user');
  console.log('Auth--=-=-=-=-=',Auth);
  
  const navigate = useNavigate();
  const navbarTogglerRef = useRef(null);

  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const handleLogout = () => {
    localStorage.clear();
    navigate('/');
    setShowLogoutModal(false);
  }

  const handleCloseModal = () => setShowLogoutModal(false);

  const handleShowModal = () => setShowLogoutModal(true);



  // const addGLC = () => {
  //   console.log('addGLC clicked');
  //   try {
  //     const chainIdDecimal = 10222;
  //     const chainIdHex = '0x' + chainIdDecimal.toString(16); 

  //     window.ethereum.request({
  //       method: 'wallet_addEthereumChain',
  //       params: [{
  //         chainId: chainIdHex, // Use hexadecimal chain ID
  //         chainName: 'Green Ledger Coin',
  //         nativeCurrency: {
  //           name: 'Green Ledger Coin',
  //           symbol: 'GLC',
  //           decimals: 18
  //         },
  //         rpcUrls: ['https://glc-dataseed.glscan.io/'],
  //         blockExplorerUrls: ['https://glscan.io/']
  //       }]
  //     }).catch((error) => {
  //       console.log(error);

  //       // toast.error('Please download a wallet to join the GLC Network. You can download one from [link to wallet download page].');
  //     });
  //   } catch (error) {
  //     console.log(error);
  //     toast.dismiss()

  //     toast.error('Please install the wallet extension in your web browser to join the GLC Network as required.');
  //   }
  // };

  // const addGLC = () => {
  //   console.log('addGLC clicked');
  //   try {
  //     const chainIdDecimal = 10222;
  //     const chainIdHex = '0x' + chainIdDecimal.toString(16); 

  //     window.ethereum.request({
  //       method: 'wallet_addEthereumChain',
  //       params: [{
  //         chainId: chainIdHex, // Use hexadecimal chain ID
  //         chainName: 'Green Ledger Coin',
  //         nativeCurrency: {
  //           name: 'Green Ledger Coin',
  //           symbol: 'GLC',
  //           decimals: 18
  //         },
  //         rpcUrls: ['https://glc-dataseed.glscan.io/'],
  //         blockExplorerUrls: ['https://glscan.io/']
  //       }]
  //     }).catch((error) => {
  //       console.log(error);

  //       // toast.error('Please download a wallet to join the GLC Network. You can download one from [link to wallet download page].');
  //     });
  //   } catch (error) {
  //     console.log(error);
  //     toast.dismiss()

  //     toast.error('Please install the wallet extension in your web browser to join the GLC Network as required.');
  //   }
  // };

  const addGLC = async () => {
    console.log('addGLC clicked');
    try {
      const chainIdDecimal = 10222;
      const chainIdHex = '0x' + chainIdDecimal.toString(16);


      const currentChainId = await window.ethereum.request({ method: 'eth_chainId' });
      if (currentChainId === chainIdHex) {
        toast.success('You are already connected to the GLC Network!');
        return;
      }


      window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [{
          chainId: chainIdHex,
          chainName: 'Green Ledger Coin',
          nativeCurrency: {
            name: 'Green Ledger Coin',
            symbol: 'GLC',
            decimals: 18
          },
          rpcUrls: ['https://glc-dataseed.glscan.io/'],
          blockExplorerUrls: ['https://glscan.io/']
        }]
      }).catch((error) => {
        console.log(error);
        // toast.error('Please download a wallet to join the GLC Network. You can download one from [link to wallet download page].');
      });
    } catch (error) {
      console.log(error);
      toast.dismiss();
      toast.error('Please install the wallet extension in your web browser to join the GLC Network as required.');
    }
  };
  const handleClick = (event) => {
    event.preventDefault();
    addGLC();

  };

  return (
    <header>
      <div className="container">
        <nav className="navbar navbar-expand-lg" id="op" data-bs-theme="dark">
          <div className="container px-0">
            <NavLink className="nav-link nav-link text-light" aria-current="page" to="/">
              <img src={gl_logoa} alt="logo" className='mainlogo' />
            </NavLink>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" >

              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0" >
                <li className="nav-item">
                  <NavLink className="nav-link active w  inter" aria-current="page" to="/">
                    Home
                  </NavLink>
                </li>
                <li className="nav-item dropdown" >
                  <a className="nav-link dropdown-toggle w " href="#" id="blockchainDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Blockchain
                  </a>
                  <ul className="dropdown-menu" aria-labelledby="blockchainDropdown" >
                    <li><NavLink className="dropdown-item" to="/Blockchain">Transaction</NavLink></li>
                    <li><NavLink className="dropdown-item" to="/Blockesd">Blocks</NavLink></li>
                    <li><NavLink className="dropdown-item" to="/contracttransaction">Contract Transaction</NavLink></li>
                    <li><NavLink className="dropdown-item" to="/Topaccount">Top Accounts</NavLink></li>
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle w" href="#" id="resourcesDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Resources
                  </a>
                  <ul className="dropdown-menu" aria-labelledby="resourcesDropdown">
                    <li><a className="dropdown-item" onClick={handleClick} href="#">Join GLC Network</a></li>
                    <li><NavLink className="dropdown-item" to="Chart">Charts &amp; Stats</NavLink></li>
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle w" href="#" id="developerApiDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Developer API
                  </a>
                  <ul className="dropdown-menu" aria-labelledby="developerApiDropdown">
                    <li><NavLink
                      className="dropdown-item"
                      to="/Apiplan"

                    >
                      API Plans
                    </NavLink></li>
                    <li><NavLink className="dropdown-item" to="/APIDocumentation">API Documentation</NavLink></li>
                    <li><NavLink className="dropdown-item" to="/Viewcontract">Verify Contract</NavLink></li>
                    <li><NavLink className="dropdown-item" to="/Contractsearch">Similar Contract Search</NavLink></li>
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle w" href="#" id="tokenDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Token
                  </a>
                  <ul className="dropdown-menu" aria-labelledby="tokenDropdown" >
                    <li><NavLink className="dropdown-item" to="/Veiwtoken" >Token</NavLink></li>
                    <li><NavLink className="dropdown-item" to="/Tokentransfer">Token Transaction</NavLink></li>
                  </ul>
                </li>
              </ul>
              <div className="container ">
                <ul className="navbar-nav">
                  {Auth ? (
                    <li className="nav-item dropdown t" >
                      <a
                        className="nav-link dropdown-toggle w f inter"
                        href="#"
                        id="navbarDropdown"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {/* {Auth.username} */}
                        <img class="nav-user-img" src={user} alt=""></img>
                      </a>
                      <ul className="dropdown-menu" aria-labelledby="navbarDropdown" style={{ color: 'white' }}>
                        <li>
                          <NavLink className="dropdown-item w inter " to="/Profile">
                            Account Overview
                          </NavLink>
                        </li>

                        <li>
                          <NavLink className="dropdown-item w inter " to="/Accountsettings">
                            Account Settings
                          </NavLink>
                        </li>
                        <li>
                          <NavLink className="dropdown-item w inter " to="/Listwatch">
                            Watch List
                          </NavLink>
                        </li>
                        <li>
                          <NavLink className="dropdown-item w inter " to="/Tokenignorelist">
                            Token Ignore List
                          </NavLink>
                        </li>


                        <li style={{ paddingLeft: "20px" }}>
                          {/* <NavLink onClick={logout} className="nav-link" to="/" >Logout ({JSON.parse(Auth).name})</NavLink>
                             */}
                          <NavLink onClick={handleShowModal} className="nav-link" to="/">Logout ({JSON.parse(Auth).name})</NavLink>
                        </li>
                      </ul>
                    </li>
                  ) : (
                    <li className="nav-item">
                      <NavLink className="nav-link w inter" to="/LoginPage">
                        Sign In
                      </NavLink>
                    </li>

                  )}
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </div>
      <Modal show={showLogoutModal} onHide={handleCloseModal} >
        <Modal.Header closeButton style={{ backgroundColor: "black", color: "#D0F459" }} className='b'>
          <Modal.Title>Confirm Logout</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: "#10212a", color: "white" }} >Are you sure you want to logout?</Modal.Body>
        <Modal.Footer style={{ backgroundColor: "black" }}>
          <Button onClick={handleCloseModal} style={{ backgroundColor: "black", color: "#D0F459" }}>
            Cancel
          </Button>
          <Button onClick={handleLogout} style={{ backgroundColor: "black", color: "#D0F459" }}>
            Logout
          </Button>
        </Modal.Footer>
      </Modal>
    </header>
  );
};

export default Navbar;
