
import { useAuth } from './AuthContext';
import { Outlet, Navigate } from 'react-router-dom';

const PrivateComponent = () => {
  const { user } = useAuth();
  // const token = localStorage.getItem('token');

  return user ? <Outlet /> : <Navigate to="/" />;
};
export default PrivateComponent;