import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
// import black from './Img/black.png';
import Green from './Img/Green.png'
import { toast } from 'react-toastify'; // Import toast
import { base_url } from './config/Api';

const Account = () => {
  const [otp, setOtp] = useState('');
  const [error, setError] = useState('');
  const [resendSuccess, setResendSuccess] = useState(false);
  const [resendDisabled, setResendDisabled] = useState(false); // To track whether resend is disabled
  const navigate = useNavigate();
  const location = useLocation();


  const email = new URLSearchParams(location.search).get('email');


  const handleVerify = async (e) => {
    e.preventDefault();

    try {

      if (!otp || otp.length !== 6 || !/^\d+$/.test(otp)) {
        setError('Please enter a valid OTP.');
        return;
      }


      const response = await fetch(` ${base_url}/api/account-verify`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, otp }),
      });
      const data = await response.json();
      console.log('data', data);
      if (data.success === true) {
        console.log('OTP verified successfully!');

        toast.dismiss()

        toast.success(data.message)
        navigate('/LoginPage');
      } else {
        setError('OTP verification failed. Please enter a valid OTP.');
        toast.dismiss()

        toast.error(data.message);
        console.error('OTP verification failed');
      }
    } catch (error) {
      setError('Error occurred during OTP verification.');
      console.error('Error:', error);
    }
  };


  const handleResendOtp = async () => {
    try {

      if (resendDisabled) {
        setError('Please wait before requesting a new OTP.');
        return;
      }


      const response = await fetch(`${base_url}/api/account-verify`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
      const data = await response.json();
      if (data.success === true) {
        setResendSuccess(true);
        setResendDisabled(true);


        setTimeout(() => {
          setResendDisabled(false);
        }, 60000);

        toast.dismiss()

        toast.success(data.message)
        console.log('New OTP sent successfully!');
      } else {
        setError('Error resending OTP. Please try again.');
        console.error('Error resending OTP');
        toast.dismiss()

        toast.error(data.message)
      }
    } catch (error) {
      setError('Error occurred during OTP resend.');
      console.error('Error:', error);
    }
  };


  useEffect(() => {
    if (resendSuccess) {

      // toast.success('OTP resent successfully!')
      console.log('OTP resent successfully!');
    }
  }, [resendSuccess]);

  return (
    <div className="section2 mt-5">
      <div className="container">
        <div className="table-sec table-sec1">
          <div className="row">
            <div className="col-lg-7 login-form">
              <form onSubmit={handleVerify}>
                {/* Display email */}
                <label className="form-label1 w afacad" htmlFor="email">
                  Email Address
                </label>
                <div className="d-flex search-form mt-3">
                  <div className="user-icon"><i className="fa-solid fa-user b" /></div>
                  <p className="form-control me-2 search-input inter w" >{email}</p>
                </div><br />

                {/* Input for OTP */}
                <label className="form-label1 w afacad" htmlFor="password">
                  OTP
                </label>
                <div className="d-flex search-form mt-3">
                  <div className="user-icon"><i className="fa-solid fa-lock b" /></div>
                  <input
                    type="text"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    className="form-control me-2 search-input inter" id="username"
                  />
                </div>
                {/* {error && <p className="error-message" style={{ color: "red" }}>{error}</p>} */}
                <br />
                <div className='d-flex'>
                  {/* Submit button for OTP verification */}
                  <button className="form-submit afacad b Login" type="submit">
                    Submit
                  </button>

                  {/* Resend button with functionality */}
                  <a className="g form-label1 w afacad" style={{ marginLeft: "140px" }} onClick={handleResendOtp} disabled={resendDisabled}>
                    RESEND OTP
                  </a>
                </div>
                {resendDisabled && <p className="error-message" style={{ color: "red" }}>Please wait before requesting a new OTP.</p>}
              </form>
            </div>
            <div className="col-lg-5 login-form-col2">
              <div className="login-box">
                <img className="logo-sign" src={Green} alt="" />
                <h4 className="form-title b afacad">Account Verification</h4>
                <p className="form-label1 b inter">
                  {/* Additional content if needed */}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Account;
