import React, { useState, useEffect } from "react";
import { toast } from 'react-toastify';
import { base_url } from "../../config/Api";

const Contactus = () => {

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    // contactNo: "",
    subject: '',
    message: "",
  });


  const [submitting, setSubmitting] = useState(false);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitting(true);
  };


  useEffect(() => {
    const sendFormData = async () => {
      try {

        const backendApiUrl = `${base_url}/api/query`;


        const response = await fetch(backendApiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        });
        const data = await response.json();

        if (data.success === true) {



          console.log(data);


          setFormData({
            name: "",
            email: "",
            // contactNo: "",
            subject: '',
            message: "",
          });
          toast.dismiss()
          toast.success(data?.message)
        } else {
          toast.dismiss()
          toast.error(data?.message);
          // console.error("Error sending data to the backend:", response.statusText);
        }
      } catch (error) {

        console.error("An unexpected error occurred:", error);
      } finally {

        setSubmitting(false);
      }
    };


    if (submitting) {
      sendFormData();
    }
  }, [submitting, formData]);


  return (
    <>
      <div className="section2 my-5">
        <div className="container">
          <div className="table-sec ">
            <div className="row">
              <div className="col-lg-6 Verify-col">
                <h4 className="chart-title w afacad">Before Contacting Support
                </h4>
                <h4 className="chart-title g afacad">Pending Transaction</h4>
                <p className="bl most inter">: We don't handle transactions and can't expedite, cancel, or replace them. Refer
                  to our guidance on pending transactions.
                </p>
                <h4 className="chart-title g afacad">GLC  Smart Chain Block Explorer               </h4>
                <p className="bl most inter">GlCScan is an independent explorer. We may not have precise
                  answers for inquiries related to other providers unless explicitly stated</p>
                <h4 className="chart-title g afacad">Knowledge Base
                </h4>
                <p className="bl most inter">Check our Knowledge Base for answers. If not found, explore related articles for assistance</p>
                <h4 className="chart-title g afacad">Community Support</h4>
                <p className="bl most inter">We never ask for private keys or passwords. Please refrain from spamming. While we aim to respond promptly,
                  there may be delays due to high volumes.
                </p>
                <h4 className="chart-title g afacad">Wallet/Exchange/Project Issues
                </h4>
                <p className="bl most inter">For support related to wallets, exchanges, or projects, contact the respective service providers. They are better
                  equipped to assist with platform-specific issues</p>
              </div>
              <div className="col-lg-6 Verify-col2">
                <form onSubmit={handleSubmit}>
                  <label className="form-label1 w afacad">Name </label><br />
                  <input
                    type="text"
                    name="name"

                    className="form-control1"
                    value={formData.name}
                    onChange={handleChange}
                    required
                    autocomplete="off"
                  />
                  <label className="form-label1 w afacad">Email </label><br />
                  <input
                    type="email"
                    name="email"

                    className="form-control1"
                    value={formData.email}
                    onChange={handleChange}
                    autocomplete="off"
                    required
                  />
                  {/* <label className="form-label1 w afacad">Contact No</label><br />
                  <input
                    type="text"
                    name="contactNo"

                    className="form-control1"
                    value={formData.contactNo}
                    onChange={handleChange}
                    required

                  /> */}

                  <label className="form-label1 w afacad">Subject </label><br />
                  <select
                    name="subject"
                    id="cars"
                    className="form-control1"
                    value={formData.subject}
                    onChange={handleChange}
                    required
                    autocomplete="off"
                  >
                    <option value={0}>Select Your Message Subject</option>
                    <optgroup label="1. Inquiries">
                      <option value={'1.a. General Inquiry'}>1.a. General Inquiry</option>
                      <option value={'1.b. Advertising'}>1.b. Advertising</option>
                      <option value={'1.c. EaaS'}>1.c. EaaS</option>
                    </optgroup>
                    <optgroup label="2. Submissions">
                      <option value={'2.a. Update Token Info'}>2.a. Update Token Info</option>
                      <option value={'2.b. Add Name Tag/Label'}>2.b. Add Name Tag/Label</option>
                      <option value={'2.c. Request Removal of Name Tag'}>2.c. Request Removal of Name Tag</option>
                      <option value={'2.d. Suggest Transaction Action'}>2.d. Suggest Transaction Action</option>
                      <option value={'2.e. Update Proxy Contracts Implementation Address'}>2.e. Update Proxy Contract's Implementation Address</option>
                    </optgroup>
                    <optgroup label="3. Security">
                      <option value={'3.a. Report Phishing Address'}>3.a. Report Phishing Address</option>
                      <option value={'3.b. Security Audit'}>3.b. Security Audit</option>
                    </optgroup>
                    <option value={'4. Priority Support'}>4. Priority Support</option>
                    <option value={'5. API Support'}>5. API Support</option>
                  </select><br />
                  <label className="form-label1 w afacad">Message</label><br />
                  <textarea
                    className="form-control1"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    required
                    autocomplete="off"
                  /><br />


                  <input
                    type="submit"
                    value="Submit"
                    className="form-submit afacad b"
                  />

                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contactus;
