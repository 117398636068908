import './main.css'
// import './App.css'
import { Route, Routes, Navigate } from 'react-router-dom';
import Navbar from './Navbar';
import Home from './Homes/Home';
import Blockesd from './Blockchains/Blockesd';
import Blockchain from './Blockchains/Blockchain'
import { AuthProvider } from './AuthContext';
import { useAuth } from './AuthContext';
import LoginPage from './LoginPage'
import SignupPage from './SignupPage'
import PrivateComponent from './Private';
import Veiwtoken from './Tokens/Veiwtoken';
import Viewcontract from './Developers/Viewcontract'
import Contract from './Hashvalue/Contract'
import ContractTransaction from './Blockchains/Transactioncontract'
import Topaccount from './Blockchains/Topaccont';
import Footer from './Footer/Footer';
import Tokentransfer from './Tokens/Tokentransfer';
import RbcGuide from './Footer/USefulllink/RbcGuide';
import RBC20Devportal from './Footer/USefulllink/RBC20Devportal'
import Profile from './User/Profile';
import Blockhash from './Hashvalue/Blockhash';
import Contactus from './Footer/RBCscan/Contactus';
import Policy from './Footer/RBCscan/Policy';
import TermsandConditions from './Footer/RBCscan/TermsandConditions';
import Apiplan from'./Developers/Apiplan'
import Account from './Account';
import Forgetpassword from './Forgetpassword';
import Newletter from './Footer/RBCscan/Newletter'
import Contractsearch from './Developers/Contractsearch';
import APIDocumentation from './Developers/APIDocumentation';
import Accountsettings from './User/Accountsettings'
import Listwatch from './User/Listwatch';
import Tokenignorelist from './User/Tokenignorelist';
import Transactionhash from './Hashvalue/Transactionhash';
import Address from './Hashvalue/Address'
import DetailyContract from './Hashvalue/DetailyContract'
import Chart from './Resources/Charts';
import BlockTransaction from './Hashvalue/tranctionBlock/Tranctionblock'
import LineCharts from './Resources/Chart'
import VerifyPublish from './Developers/VerifyPublish'
import Bytecode from './Hashvalue/Bytecode'
function App() {
  const { user } = useAuth();
  
  // console.log(user);
  return (
    
    <AuthProvider>
      <Navbar />

      <Routes>
        <Route

          element={<PrivateComponent />}
        >
    
          <Route path='/Profile' element={<Profile/>}/>
          <Route path='/Listwatch' element={<Listwatch/>}/>
          <Route path='/Accountsettings' element={<Accountsettings/>}/>
          <Route path='/Tokenignorelist' element={<Tokenignorelist/>}/>
         
        </Route>
        <Route path="/" element={<Home />} />
          <Route path="/Blockchain" element={<Blockchain />} />
          <Route path="/Blockesd" element={<Blockesd />} />
          <Route path='/Veiwtoken' element={<Veiwtoken />} />
          <Route path='/Viewcontract/:address?' element={<Viewcontract />} />
          <Route path ='Blockhash/:blockNumber' element={<Blockhash/>}/>
          <Route path='/Contracttransaction' element={<ContractTransaction/>}/>
        <Route path="/LoginPage" element={<LoginPage />} />
        <Route path="/SignupPage" element={<SignupPage />} />
        <Route path='/Apiplan' element={<Apiplan/>}/>
        <Route path='/Tokentransfer' element={<Tokentransfer/>}/>
        <Route path='/GLCGuide' element={<RbcGuide/>}/>
        <Route path='/GLC20Devportal' element={<RBC20Devportal/>}/>
        <Route path='/Topaccount' element={<Topaccount/>}/>
        <Route path='/Contactus' element={<Contactus/>}/>
        <Route path='/Policy' element={<Policy/>}/>
        <Route path='/TermsandConditions' element={<TermsandConditions/>}/>
        <Route path='/Account' element ={<Account/>}/>
        <Route path='/Forgetpassword' element ={<Forgetpassword/>}/>
        <Route path='/newletter' element={<Newletter/>}/>
        <Route path='/Contractsearch' element={<Contractsearch/>}/>
        <Route path='/APIDocumentation' element={<APIDocumentation/>}/>
        <Route path='/Contract/:address' element={<Contract/>}/>
        <Route path ='/tx/:hash' element ={<Transactionhash/>}/>
        <Route path ='/address/:address' element ={<Address/>}/>
        <Route path ='/DetailyContract/:address' element ={<DetailyContract/>}/>
        <Route path ='/Chart' element ={<Chart/>}/>
        <Route path ='/BlockTransaction' element ={<BlockTransaction/>}/>
        <Route path ='/LineCharts' element ={<LineCharts/>}/>
        <Route path ='/verifyPublish' element ={<VerifyPublish/>}/>
        <Route path ='/bytecode/:address' element ={<Bytecode/>}/>
      </Routes>
      <Footer/>
    </AuthProvider>
    
  );
}

export default App;
