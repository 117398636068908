import React, { useState, useEffect } from 'react';
import { useParams,NavLink ,useNavigate} from 'react-router-dom';
import { base_url } from '../config/Api';

const Viewcontract = () => {
  // const { address } = useParams();
  // const [addresss ,setAddresss] = useState(address);
  // const [version, setVersion] = useState([]);
  // const [licenseTypes, setLicenseTypes] = useState([]);
  // const fetchData = async () => {
  //   try {
  //     const apiUrl = `${base_url}/api/compiler-version`;
  //     const response = await fetch(apiUrl);
  //     const data = await response.json();
  //    if(data.success === true){
  //     setVersion(data.versions)
  //     setLicenseTypes(data.licenseTypes)

  //    } else{
  //     console.log('error.message');
      
  //    }
      
     
     
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
    
  //   }
  // };

  // console.log('version',version);
  

  // useEffect(() => {
  //   fetchData();
  // },[]);
  const { address } = useParams();
  const navigate = useNavigate();
  const [addresss, setAddresss] = useState(address);
  const [compilerType, setCompilerType] = useState('');
  const [compilerVersion, setCompilerVersion] = useState('');
  const [licenseType, setLicenseType] = useState('');
  const [version, setVersion] = useState([]);
  const [licenseTypes, setLicenseTypes] = useState([]);

  const fetchData = async () => {
    try {
      const apiUrl = `${base_url}/api/compiler-version`;
      const response = await fetch(apiUrl);
      const data = await response.json();
      if (data.success === true) {
        setVersion(data.versions);
        setLicenseTypes(data.licenseTypes);
      } else {
        console.log('Error:', data.message);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Form submission handler
  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = {
      address: addresss,
      compilerType,
      compilerVersion,
      licenseType,
    };

    console.log('formData',formData);
    // Navigate to the next page and send formData
    navigate('/verifyPublish', { state: { formData } });
  };


  // console.log('formData',formData);
  
  return (
    <>
      <div className="section2 my-5">
        <div className="container">
          <div className="table-sec ">
            <div className="row">
              <div className="col-lg-6 Verify-col">
                <h4 className="chart-title w afacad">
                  Verify Contract
                </h4>
                <p className="bl most inter">
                  Verify & Publish Contract Source Codes Source code verification enhances transparency for
                  users engaging with smart contracts. By uploading the source code, Etherscan matches it
                  with the compiled code on the blockchain. This process ensures users have access to
                  comprehensive information about the digital signatures they interact with and allows for
                  independent code audits to confirm functionality. Please note that advanced settings, such as
                  BytecodeHash or ViaIR, can be accessed through the Solidity verification method. Refer to
                  Solidity's "Compiler Input and Output JSON Description" documentation for more details.
                </p>
                {/* <p className="bl most inter">
                  Please Be Informed That Advanced Settings (E.G. BytecodeHash:
                  "None" Or ViaIR: "True") Can Be Accessed Via Solidity
                  (Standard-Json-Input) Verification Method. More Information
                  Can Be Found Under Solidity's "Compiler Input And Output JSON
                  Description" Documentation Section.
                </p> */}
              </div>
              <div className="col-lg-6 Verify-col2">
                {/* <form>
                  <label className="form-label1 w afacad">
                    Please enter the Contract Address you would like to verify
                  </label>
                  <br />
                  {/* <input
                    placeholder="Ox..."
                    type="text"
                    className="form-control1"
                  /> 
                   <input
                   className="form-control1"
                   
                    type="text"
                    autoComplete="off"
                    value={addresss}
                    onChange={(e) => setAddresss(e.target.value)}
                  />
                  <br />
                  <label className="form-label1 w afacad">
                    Please Select Compiler Type
                  </label>
                  <br />
                  <select
                    name="compilerType"
                    id="cars"
                    className="form-control1"
                  >
                    <option value="volvo">Open this select menu</option>
                    <option value="saab">Solidity(Single file)</option>
                    <option value="opel">Solidity (Multi-Part files)</option>
                    <option value="opel">Solidity (Standard-Json-Input)</option>
                  </select>
                  <br />
                  <label className="form-label1 w afacad">
                    Please Select Compiler Version
                  </label>
                  <br />
                  <select name="compilerVersion" id="cars" className="form-control1">
                    <option value="">Open this select menu</option>
                    {version?.length > 0 && version?.map((ver, index) => (
                      <option key={index} value={ver}>{ver}</option>
                    ))}
                  </select>


                  <br />
                  <label className="form-label1 w afacad">
                    Please Select Open Source License Type
                  </label>
                  <br />
                  {/* <select
                    name="licenseType"
                    id="cars"
                    className="form-control1"
                  >
                    <option value="volvo">Open this select menu</option>
                    <option value="saab">Solidity(Single file)</option>
                    <option value="opel">Solidity (Multi-Part files)</option>
                  </select> 
                  <select name="compilerVersion" id="cars" className="form-control1">
                    <option value="">Open this select menu</option>
                    {licenseTypes?.length > 0 && licenseTypes?.map((ver, index) => (
                      <option key={index} value={ver}>{ver}</option>
                    ))}
                  </select>
                  <br />
                  <input
                    value="Continue"
                    type="submit"
                    className="form-submit afacad b"
                  />
                  <input
                    value="Reset"
                    type="reset"
                    className="form-reset afacad g"
                  />
                </form> */}
                <form onSubmit={handleSubmit}>
                  <label className="form-label1 w afacad">
                    Please enter the Contract Address you would like to verify
                  </label>
                  <br />
                  <input
                    className="form-control1"
                    type="text"
                    autoComplete="off"
                    value={addresss}
                    onChange={(e) => setAddresss(e.target.value)}
                  />
                  <br />
                  <label className="form-label1 w afacad">
                    Please Select Compiler Type
                  </label>
                  <br />
                  <select
                    name="compilerType"
                    className="form-control1"
                      id="cars"
                    value={compilerType}
                    onChange={(e) => setCompilerType(e.target.value)}
                  >
                    <option value="">Open this select menu</option>
                    <option value="Single file">Solidity (Single file)</option>
                    <option value="Multi-Part files">Solidity (Multi-Part files)</option>
                    <option value="Standard-Json-Input">Solidity (Standard-Json-Input)</option>
                  </select>
                  <br />
                  <label className="form-label1 w afacad">
                    Please Select Compiler Version
                  </label>
                  <br />
                  <select
                    name="compilerVersion"
                    className="form-control1"
                      id="cars"
                    value={compilerVersion}
                    onChange={(e) => setCompilerVersion(e.target.value)}
                  >
                    <option value="">Open this select menu</option>
                    {version?.length > 0 &&
                      version?.map((ver, index) => (
                        <option key={index} value={ver}>
                          {ver}
                        </option>
                      ))}
                  </select>
                  <br />
                  <label className="form-label1 w afacad">
                    Please Select Open Source License Type
                  </label>
                  <br />
                  <select
                    name="licenseType"
                    className="form-control1"
                      id="cars"
                    value={licenseType}
                    onChange={(e) => setLicenseType(e.target.value)}
                  >
                    <option value="">Open this select menu</option>
                    {licenseTypes?.length > 0 &&
                      licenseTypes?.map((type, index) => (
                        <option key={index} value={type}>
                          {type}
                        </option>
                      ))}
                  </select>
                  <br />
                  <input
                    value="Continue"
                    type="submit"
                    className="form-submit afacad b"
                  />
                  <input
                    value="Reset"
                    type="reset"
                    className="form-reset afacad g"
                    onClick={() => {
                      setAddresss(address);
                      setCompilerType('');
                      setCompilerVersion('');
                      setLicenseType('');
                    }}
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Viewcontract;



// import React, { useState, useEffect } from 'react';
// import { useParams, useNavigate } from 'react-router-dom';
// import { base_url } from '../config/Api';

// const Viewcontract = () => {
//   const { address } = useParams();
//   const navigate = useNavigate();
//   const [addresss, setAddresss] = useState(address);
//   const [compilerType, setCompilerType] = useState('');
//   const [compilerVersion, setCompilerVersion] = useState('');
//   const [licenseType, setLicenseType] = useState('');
//   const [version, setVersion] = useState([]);
//   const [licenseTypes, setLicenseTypes] = useState([]);

//   const fetchData = async () => {
//     try {
//       const apiUrl = `${base_url}/api/compiler-version`;
//       const response = await fetch(apiUrl);
//       const data = await response.json();
//       if (data.success === true) {
//         setVersion(data.versions);
//         setLicenseTypes(data.licenseTypes);
//       } else {
//         console.log('Error:', data.message);
//       }
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     }
//   };

//   useEffect(() => {
//     fetchData();
//   }, []);

//   // Form submission handler
//   const handleSubmit = (e) => {
//     e.preventDefault();

//     const formData = {
//       address: addresss,
//       compilerType,
//       compilerVersion,
//       licenseType,
//     };

//     // Navigate to the next page and send formData
//     navigate('/target-page', { state: { formData } });
//   };

//   return (
//     <>
//       <div className="section2 my-5">
//         <div className="container">
//           <div className="table-sec">
//             <div className="row">
//               <div className="col-lg-6 Verify-col">
//                 <h4 className="chart-title w afacad">Verify Contract</h4>
//                 <p className="bl most inter">
//                   Verify & Publish Contract Source Codes...
//                 </p>
//               </div>
//               <div className="col-lg-6 Verify-col2">
//                 <form onSubmit={handleSubmit}>
//                   <label className="form-label1 w afacad">
//                     Please enter the Contract Address you would like to verify
//                   </label>
//                   <br />
//                   <input
//                     className="form-control1"
//                     type="text"
//                     autoComplete="off"
//                     value={addresss}
//                     onChange={(e) => setAddresss(e.target.value)}
//                   />
//                   <br />
//                   <label className="form-label1 w afacad">
//                     Please Select Compiler Type
//                   </label>
//                   <br />
//                   <select
//                     name="compilerType"
//                     className="form-control1"
//                     value={compilerType}
//                     onChange={(e) => setCompilerType(e.target.value)}
//                   >
//                     <option value="">Open this select menu</option>
//                     <option value="single">Solidity (Single file)</option>
//                     <option value="multi">Solidity (Multi-Part files)</option>
//                     <option value="json">Solidity (Standard-Json-Input)</option>
//                   </select>
//                   <br />
//                   <label className="form-label1 w afacad">
//                     Please Select Compiler Version
//                   </label>
//                   <br />
//                   <select
//                     name="compilerVersion"
//                     className="form-control1"
//                     value={compilerVersion}
//                     onChange={(e) => setCompilerVersion(e.target.value)}
//                   >
//                     <option value="">Open this select menu</option>
//                     {version?.length > 0 &&
//                       version?.map((ver, index) => (
//                         <option key={index} value={ver}>
//                           {ver}
//                         </option>
//                       ))}
//                   </select>
//                   <br />
//                   <label className="form-label1 w afacad">
//                     Please Select Open Source License Type
//                   </label>
//                   <br />
//                   <select
//                     name="licenseType"
//                     className="form-control1"
//                     value={licenseType}
//                     onChange={(e) => setLicenseType(e.target.value)}
//                   >
//                     <option value="">Open this select menu</option>
//                     {licenseTypes?.length > 0 &&
//                       licenseTypes?.map((type, index) => (
//                         <option key={index} value={type}>
//                           {type}
//                         </option>
//                       ))}
//                   </select>
//                   <br />
//                   <input
//                     value="Continue"
//                     type="submit"
//                     className="form-submit afacad b"
//                   />
//                   <input
//                     value="Reset"
//                     type="reset"
//                     className="form-reset afacad g"
//                     onClick={() => {
//                       setAddresss(address);
//                       setCompilerType('');
//                       setCompilerVersion('');
//                       setLicenseType('');
//                     }}
//                   />
//                 </form>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Viewcontract;

