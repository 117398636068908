// import React from 'react'
// import { base_url } from '../config/Api';
// import { useState, useEffect } from 'react';
// import { NavLink, useParams } from 'react-router-dom';


// const Bytecode = () => {
//     const { address } = useParams();
//     const [data, setData] = useState()
//     const [datadecompile, setDataDecompile] = useState()
//     const sendFormData = async () => {
//         try {
//             const requestData = {
//                 contractAddress: address
    
//             };
    
//             const backendApiUrl = `${base_url}/api/bytecode`;
    
    
//             const response = await fetch(backendApiUrl, {
//                 method: "POST",
//                 headers: {
//                     "Content-Type": "application/json",
//                 },
//                 body: JSON.stringify(requestData),
//             });
//             const dataa = await response.json();
    
//             if (dataa.success === true) {
    
    
    
//                 console.log(data);
    
    
//                 setData(dataa?.bytecode);
//                 // toast.dismiss()
//                 alert.success(dataa?.message)
//             } else {
//                 // toast.dismiss()
//                 alert.error(dataa?.message);
//                 // console.error("Error sending data to the backend:", response.statusText);
//             }
//         } catch (error) {
    
//             console.error("An unexpected error occurred:", error);
//         }
//     };
//     const sendForm = async () => {
//         try {
//             const requestData = {
//                 bytecode: data
    
//             };
    
//             const backendApiUrl = `${base_url}/api/decompile`;
    
    
//             const response = await fetch(backendApiUrl, {
//                 method: "POST",
//                 headers: {
//                     "Content-Type": "application/json",
//                 },
//                 body: JSON.stringify(requestData),
//             });
//             const dates = await response.json();
    
//             if (dates.success === true) {
    
    
    
//                 // console.log(dates);
    
    
//                 setDataDecompile(dates);
//                 // toast.dismiss()
//                 alert.success(dates?.message)
//             } else {
//                 // toast.dismiss()
//                 alert.error(dates?.message);
//                 // console.error("Error sending data to the backend:", response.statusText);
//             }
//         } catch (error) {
    
//             console.error("An unexpected error occurred:", error);
//         }
//     };

//     useEffect(() => {
//         sendFormData();
//     } ,[address])
//     return (
//         <>
//             <div className='table-sec '>
//                 <div className="section2 mt-3">
                    
//                     <div className="container">
//                     <h4 className="chart-title w afacad mt-3">EVM bytecode decompiler (Experimental)</h4>
//                     <hr></hr>
//                     <p className='w afacad mt-3'>GLC Smart Chain Virtual Machine (EVM) decompiler for extracting information from Runtime bytecode and presenting it in a more human-readable form. Useful for debugging smart contracts where the original source code is not available or unverified.</p>
                       
                       
//                         {/* <textarea className="form-control text-muted text-monospace p-3" rows={7} id="extraData" defaultValue={"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."}></textarea> */}
//                         <textarea
//                                                         className="form-control text-muted text-monospace p-3"
//                                                         rows={7}
//                                                         id="extraData"
//                                                         value={data}
//                                                         readOnly
//                                                     />
//                    <button className='view-btn b afacad' onClick={sendForm} >Decompile Bytecode</button>
//                     </div>
//                 </div>
//             </div>
//         </>
//     )
// }

// export default Bytecode



import React, { useState, useEffect } from 'react';
import { base_url } from '../config/Api';
import { useParams } from 'react-router-dom';

const Bytecode = () => {
    const { address } = useParams();
    const [data, setData] = useState();
    const [datadecompile, setDataDecompile] = useState(null);
    const [showDecompile, setShowDecompile] = useState(false); // control visibility of additional section

    const sendFormData = async () => {
        try {
            const requestData = { contractAddress: address };
            const backendApiUrl = `${base_url}/api/bytecode`;

            const response = await fetch(backendApiUrl, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(requestData),
            });
            const dataa = await response.json();

            if (dataa.success === true) {
                setData(dataa?.bytecode);
                alert.success(dataa?.message);
            } else {
                alert.error(dataa?.message);
            }
        } catch (error) {
            console.error("An unexpected error occurred:", error);
        }
    };

    const sendForm = async () => {
        try {
            const requestData = { bytecode: data };
            const backendApiUrl = `${base_url}/api/decompile`;

            const response = await fetch(backendApiUrl, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(requestData),
            });
            const dates = await response.json();

            if (dates.success === true) {
                setDataDecompile(dates);  
                setShowDecompile(true);  
                alert.success(dates?.message);
            } else {
                alert.error(dates?.message);
            }
        } catch (error) {
            console.error("An unexpected error occurred:", error);
        }
    };

    useEffect(() => {
        sendFormData();
    }, [address]);

    return (
        <>
            <div className='table-sec'>
                <div className="section2 mt-3">
                    <div className="container">
                        <h4 className="chart-title w afacad mt-3">EVM bytecode decompiler (Experimental)</h4>
                        <hr />
                        <p className='w afacad mt-3'>GLC Smart Chain Virtual Machine (EVM) decompiler for extracting information from Runtime bytecode and presenting it in a more human-readable form. Useful for debugging smart contracts where the original source code is not available or unverified.</p>
                        
                        <textarea
                            className="form-control text-muted text-monospace p-3"
                            rows={7}
                            id="extraData"
                            value={data}
                            readOnly
                        />

                        <button className='view-btn b afacad' onClick={sendForm}>Decompile Bytecode</button>

                        {/* {showDecompile && (  // Conditionally render the decompiled section
                            <div className='decompiled-section mt-4'>
                               
                                <textarea
                                    className="form-control text-muted text-monospace p-3"
                                    rows={7}
                                    value={datadecompile.decompile}
                                    readOnly
                                     id="extraData"
                                />
                            </div>
                        )} */}
                        {showDecompile && datadecompile && (
    <div className='decompiled-section mt-4'>
       
        {/* Display the success message */}
        <textarea
            className="form-control text-muted text-monospace p-3"
            rows={10}
            value={datadecompile.decompile}
            readOnly
             id="extraData"
        />
    </div>
)}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Bytecode;

