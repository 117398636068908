import React, { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import { base_url } from '../../config/Api';
import Web3 from 'web3'; 

const Table = () => {
  const [transactions, setTransactions] = useState([]);
  const [totalTransactions, setTotalTransactions] = useState(null);
  const [loading, setLoading] = useState(true);
  const web3Instance = new Web3('http://localhost:8545'); // Replace with your Ethereum node URL if needed
  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await fetch(`${base_url}/api/transaction`);
        if (!response.ok) {
          throw new Error(`Failed to fetch transactions. Status: ${response.status}`);
        }

        const result = await response.json();

        if (result && Array.isArray(result.transactions)) {
          setTransactions(result.transactions);
          setTotalTransactions(result.totalTransactions);
        } else {
          console.error('Invalid or missing transaction data in the API response:', result);
        }

        setLoading(false);
      } catch (error) {
        console.error('Error fetching transaction data:', error);
        setLoading(false);
      }
    };

    fetchTransactions();
  }, []);

  return (
    <>
      <div className="section2">
        <div className="container">
          <div className="table-sec">
            <div className='tab'>
              <h4 className="chart-title w afacad">{`Latest 10 Transactions Found`}</h4>
            </div>
            <div className="transactions">
              <table className="my-3 found-table2 ">
                <thead className='table-responsive'>
                  <tr className="table-row">
                    <th className="tabel-heading w afacad">#</th>
                    <th className="tabel-heading w afacad">Txn Hash</th>
                    <th className="tabel-heading w afacad">Method</th>
                    <th className="tabel-heading w afacad">Block</th>
                    <th className="tabel-heading w afacad">Age</th>
                    <th className="tabel-heading w afacad">From</th>
                    <th className="tabel-heading w afacad">To</th>
                    <th className="tabel-heading w afacad">Value</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr className="table-row">
                      <td colSpan="8">
                        <Skeleton count={10} height={40} baseColor="#202020" highlightColor="#444" />
                      </td>
                    </tr>
                  ) : (
                    transactions.map((txn, index) => (
                      <tr className="table-row" key={index}>
                        <td className="tabel-text w inter">{index + 1}</td>
                        <td className="tabel-text w inter">{txn.hash ? txn.hash.slice(0, 8) : <Skeleton width={50} />}</td>
                        <td className="tabel-text transfer inter">
                          <span>{txn.methodId || <Skeleton width={50} />}</span>
                        </td>
                        <td className="tabel-text w inter">{txn.blockNumber || <Skeleton width={50} />}</td>
                        <td className="tabel-text w inter">{txn.updatedAt || <Skeleton width={50} />}</td>
                        <td className="tabel-text w inter"><span>{txn.from ? txn.from.slice(0, 8) : <Skeleton width={50} />}</span></td>
                        <td className="tabel-text w inter">{txn.to ? txn.to.slice(0, 8) : <Skeleton width={50} />}</td>
                        <td className="tabel-text w inter">{txn.value? parseFloat(web3Instance.utils.fromWei(txn.value, 'ether')).toFixed(4) : <Skeleton width={50} />}</td>

                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Table;
