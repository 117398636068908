import React from 'react'
import { useState, useEffect } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import Pagination from 'react-bootstrap/Pagination';
import Dropdown from './Dropdownsvg';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { base_url } from '../config/Api';
const Contract = () => {
    const [transactions, setTransactions] = useState([]);
    const { address } = useParams();
    const [isOpcodesView, setIsOpcodesView] = useState(false);
    const [counttransactions, setcounttransactions] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedItemsPerPage, setSelectedItemsPerPage] = useState(10);
    const [cumulativeIndex, setCumulativeIndex] = useState(0);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState()
    useEffect(() => {
        const fetchTransactions = async () => {
            try {
                setLoading(true);
                const response = await fetch(`  ${base_url}/api/transaction/address/${address}?page=${currentPage}&limit=${selectedItemsPerPage}`);
                if (!response.ok) {
                    throw new Error(`Failed to fetch transactions. Status: ${response.status}`);
                }

                const result = await response.json();

                if (result && Array.isArray(result.transactionByAddress)) {
                    console.log(result.transactionByAddress)
                    setTransactions(result.transactionByAddress);
                    setcounttransactions(result.txnCount);
                } else {
                    console.error('Invalid or missing transaction data in the API response:', result);
                }
                setLoading(false);
            } catch (error) {
                console.error('Error fetching transaction data:', error);
                setLoading(false);
            }
        };
        sendFormData()
        fetchTransactions();
    }, [counttransactions, currentPage, selectedItemsPerPage]);

    const handleItemsPerPageChange = (selectedValue) => {
        setSelectedItemsPerPage(selectedValue);
        setCurrentPage(1);
        setCumulativeIndex(0);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
        setCumulativeIndex((page - 1) * selectedItemsPerPage);
    };
    const pagination = () => {
        const totalPages = Math.ceil(counttransactions / selectedItemsPerPage);
        const pages = [];

        pages.push(
            <Pagination.First key="first" onClick={() => handlePageChange(1)} />,
            <Pagination.Prev key="prev" onClick={() => handlePageChange(currentPage - 1)} />
        );

        for (let i = Math.max(1, currentPage - 2); i <= Math.min(totalPages, currentPage + 2); i++) {
            pages.push(
                <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
                    {i}
                </Pagination.Item>
            );
        }

        pages.push(
            <Pagination.Next key="next" onClick={() => handlePageChange(currentPage + 1)} />,
            <Pagination.Last key="last" onClick={() => handlePageChange(totalPages)} />
        );

        return <Pagination>{pages}</Pagination>;
    };
    const copyText = (text) => {
        const textarea = document.createElement("textarea");
        textarea.value = text;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand("copy");
        document.body.removeChild(textarea);
    };

    const sendFormData = async () => {
        try {
            const requestData = {
                contractAddress: address

            };

            const backendApiUrl = `${base_url}/api/bytecode`;


            const response = await fetch(backendApiUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(requestData),
            });
            const dataa = await response.json();

            if (dataa.success === true) {



                console.log(data);


                setData(dataa);
                // toast.dismiss()
                alert.success(dataa?.message)
            } else {
                // toast.dismiss()
                alert.error(dataa?.message);
                // console.error("Error sending data to the backend:", response.statusText);
            }
        } catch (error) {

            console.error("An unexpected error occurred:", error);
        }
    };



    const handleToggleView = () => {
        setIsOpcodesView(!isOpcodesView);
    };


    return (
        <div>
            <div className="section1 my-5">
                <div className="container">
                    <div className="row section1-box">
                        <div className="col-lg-4 col-md-4 col_1">
                            <h4 className="chart-title w afacad mb-3">Overview</h4>
                            <div className="d-flex icon-box icon-box1">
                                <div className="icon-box-text">
                                    <p className="icon-box-title bl inter">GLC BALANCE</p>
                                    <p className="icon-box-sub1 w afacad">0.198748563391207414 BNB</p>
                                </div>
                            </div>
                            <div className="d-flex icon-box icon-box1 icon-box2">
                                <div className="icon-box-text">
                                    <p className="icon-box-title bl inter">GLC VALUE</p>
                                    <p className="icon-box-sub1 w afacad">$63.20 (@ $317.99/BNB)</p>
                                </div>
                            </div>
                            <div className="d-flex icon-box icon-box2">
                                <div className="icon-box-text">
                                    <p className="icon-box-title bl inter">TOKEN HOLDINGS</p>
                                    <form className="d-flex search-form mt-3">
                                        <select name="fill" id="fill" className="form-control me-2 search-input inter">
                                            <option value="volvo">
                                                Binance-Peg ... (BUSD)
                                                21 BUSD
                                                $21.02
                                                @1.0009
                                            </option>
                                            <option value="saab">Saab</option>
                                            <option value="opel">Opel</option>
                                            <option value="audi">Audi</option>
                                        </select>
                                        <button className="search-btn" type="submit"><i className="fa-solid fa-wallet" /></button>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col_1">
                            <h4 className="chart-title w afacad mb-3">More Info</h4>
                            <div className="d-flex icon-box icon-box1">
                                <div className="icon-box-text">
                                    <p className="icon-box-title bl inter">PRIVATE NAME TAGS</p>
                                    <p className="icon-box-sub1 w afacad"> <a href="#"> <span className="last_text-bg w">+ Add</span></a></p>
                                </div>
                            </div>
                            <div className="d-flex icon-box icon-box1 icon-box2">
                                <div className="icon-box-text">
                                    <p className="icon-box-title bl inter">CONTRACT CREATOR</p>
                                    <p className="icon-box-sub1 w afacad d-flex"> <span id="sample" className="g"><a href="#" className="bhcjb w">0x65d79d5f7b9e26780b60e568cad723207be92f441056221540af99dc181543c9</a></span>
                                        <button className="copy-btn" onclick="copyText()"><i className="fa-regular fa-copy g" /></button></p>
                                </div>
                            </div>
                            <div className="d-flex icon-box icon-box2">
                                <div className="icon-box-text">
                                    <p className="icon-box-title bl inter">TOKEN TRACKER                      </p>
                                    <p className="icon-box-sub1 w afacad"><a className="icon-box-sub1 w afacad" href="#">GLC</a></p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <h4 className="chart-title w afacad mb-3">Multichain Info</h4>
                            <a href="#"> <span className="last_text-bg w">$237 (Multichain Portfolio)</span></a>
                            <p className="icon-box-sub1 w afacad my-4">9 addresses found via</p>
                            <div className="dropdown">

                                <div class="dropdown-container">

                                    <Dropdown />


                                </div>
                            </div>
                            <p />
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <ul className="nav nav-tabs myTab-contract" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button className="nav-link tab-btn_p tab-btn_p1 afacad b active" id="transactions-tab" data-bs-toggle="tab" data-bs-target="#transactions" type="button" role="tab" aria-controls="transactions" aria-selected="true">Transactions</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link tab-btn_p b afacad" id="internal-tab" data-bs-toggle="tab" data-bs-target="#internal" type="button" role="tab" aria-controls="internal" aria-selected="false">Token Transfers</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link tab-btn_p b afacad" id="token-tab" data-bs-toggle="tab" data-bs-target="#token" type="button" role="tab" aria-controls="token" aria-selected="false">Contract </button>
                    </li>

                </ul>
            </div>
            <div className="section2 mt-3">
                <div className="container">

                    <div className="table-sec ">
                        <div className="tab-content" id="myTabContent">

                            <div className="tab-pane fade show active" id="transactions" role="tabpanel" aria-labelledby="transactions-tab">
                                <div className='tab '>
                                    <h4 className="chart-title w afacad mt-3"> {` Latest 10 from a total of ${counttransactions} transactions`}</h4>
                                    <select className="ms-4 " value={selectedItemsPerPage} onChange={(e) => handleItemsPerPageChange(Number(e.target.value))}>
                                        <option value={10}>10</option>
                                        <option value={25}>25</option>
                                        <option value={50}>50</option>
                                        <option value={100}>100</option>

                                    </select>
                                </div>
                                <div className="transactions">
                                    {loading ? (
                                        <Skeleton count={10} height={50} baseColor="#202020" highlightColor="#444" />
                                    ) : (
                                        <table className="my-3 found-table5">
                                            <tbody>
                                                <tr className="table-row">
                                                    <th className="tabel-heading w afacad">#</th>
                                                    <th className="tabel-heading w afacad">Transaction Hash</th>
                                                    <th className="tabel-heading w afacad">Method</th>
                                                    <th className="tabel-heading w afacad">Block</th>
                                                    <th className="tabel-heading w afacad">Date Time (UTC)</th>
                                                    <th className="tabel-heading w afacad">From</th>
                                                    <th className="tabel-heading w afacad">To</th>
                                                    <th className="tabel-heading w afacad">Value</th>
                                                    <th className="tabel-heading w afacad">Txn Fee</th>
                                                </tr>
                                                {transactions.map((transaction, index) => (
                                                    <tr className="table-row" key={index}>
                                                        <td className="tabel-text w inter">{index + 1}</td>
                                                        <td className="tabel-text w inter">{transaction.hash?.slice(0, 8)}</td>
                                                        <td className="tabel-text transfer inter"><span>{transaction.methodId}</span></td>
                                                        <td className="tabel-text w inter">{transaction.blockNumber}</td>
                                                        <td className="tabel-text w inter">{transaction.updatedAt?.slice(0, 10)}</td>
                                                        <td className="tabel-text w inter">
                                                            <p className="icon-box-sub1 w afacad d-flex">
                                                                <span className="g">
                                                                    <a href="#" className="bhcjb w">{transaction.from.slice(0, 10)}</a>
                                                                </span>
                                                                <button className="copy-btn" onClick={() => copyText(transaction.from)}>
                                                                    <i className="fa-regular fa-copy g" />
                                                                </button>
                                                            </p>
                                                        </td>
                                                        <td className="tabel-text w inter">
                                                            <p className="icon-box-sub1 w afacad d-flex">
                                                                <span className="g">
                                                                    <a href="#" className="bhcjb w">{transaction.to.slice(0, 10)}</a>
                                                                </span>
                                                                <button className="copy-btn" onClick={() => copyText(transaction.to)}>
                                                                    <i className="fa-regular fa-copy g" />
                                                                </button>
                                                            </p>
                                                        </td>
                                                        <td className="tabel-text w inter">{transaction.value.slice(0, 10)}</td>
                                                        <td className="tabel-text w inter">{transaction.gasPrice.slice(0, 10)}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    )}
                                    {pagination()}
                                </div>
                            </div>
                            <div className="tab-pane fade" id="internal" role="tabpanel" aria-labelledby="internal-tab">
                                <h4 className="chart-title w afacad mt-3">  Latest 25 internal transactions (View All)</h4>
                                <div className="transactions">

                                    <table className="my-3 found-table5">
                                        <tbody><tr className="table-row">
                                            <th className="tabel-heading w afacad">#</th>
                                            <th className="tabel-heading w afacad">Txn Hash</th>
                                            <th className="tabel-heading w afacad">Method</th>
                                            <th className="tabel-heading w afacad"> Date Time</th>
                                            <th className="tabel-heading w afacad">From</th>
                                            <th className="tabel-heading w afacad">To </th>
                                            <th className="tabel-heading w afacad">Value </th>
                                            <th className="tabel-heading w afacad">Token </th>
                                        </tr>
                                            <tr className="table-row">
                                                <td className="tabel-text w inter">1</td>
                                                <td className="tabel-text w inter">0xc5a....c25fd</td>
                                                <td className="tabel-text w inter">35488013</td>
                                                <td className="tabel-text w inter">2024-01-23 5:58:19</td>
                                                <td className="tabel-text w inter"><p className="icon-box-sub1 w afacad"><span id="sample"> Ethereum: ETH Token              </span>
                                                    <button className="copy-btn" onclick="copyText()"><i className="fa-regular fa-copy g" /></button></p></td>
                                                <td className="tabel-text w inter"><p className="icon-box-sub1 w afacad"><span id="sample"> 0xa2823F...65d6845F              </span>
                                                    <button className="copy-btn" onclick="copyText()"><i className="fa-regular fa-copy g" /></button></p></td>
                                                <td className="tabel-text w inter">0.00001 BNB</td>
                                                <td className="tabel-text w inter">0xc5a....c25fd</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="token" role="tabpanel" aria-labelledby="token-tab">
                                <p className='chart-title w afacad '> Are you the contract creator?
                                    <NavLink to={`/Viewcontract/${address}`} > Verify and Publish  </NavLink>

                                    {/* <NavLink to='/Viewcontract' > Verify and Publish  </NavLink> */}
                                    your contract source code today!</p>

                                <ul className="nav nav-tabs myTab-contract" id="myTab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link tab-btn_p tab-btn_p1 afacad b " id="code-tab" data-bs-toggle="tab" data-bs-target="#code" type="button" role="tab" aria-controls="code" aria-selected="true"> <NavLink to={`/bytecode/${address}`} className="nav-link tab-btn_p tab-btn_p1 afacad b ">  Decompile Bytecode</NavLink> </button>
                                    </li>
                                    {/* <li className="nav-item" role="presentation">
                                        <button className="nav-link tab-btn_p b afacad" id="read-contract-tab" data-bs-toggle="tab" data-bs-target="#read-contract" type="button" role="tab" aria-controls="read-contract" aria-selected="false">
                                        Switch to Opcodes View</button>
                                    </li> */}
                                    <li className="nav-item" role="presentation">
                                        <button
                                            className="nav-link tab-btn_p b afacad"
                                            id="read-contract-tab"
                                            data-bs-toggle="tab"
                                            data-bs-target="#read-contract"
                                            type="button"
                                            role="tab"
                                            aria-controls="read-contract"
                                            aria-selected="false"
                                            onClick={handleToggleView} // Toggle view on click
                                        >
                                            {isOpcodesView ? "Switch to Bytecode View" : "Switch to Opcodes View"}
                                        </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link tab-btn_p b afacad" data-bs-toggle="tab" data-bs-target="#write-contract" type="button" role="tab" aria-controls="write-contract" aria-selected="false"> <NavLink to='/Contractsearch' className="nav-link tab-btn_p tab-btn_p1 afacad b ">Similar Contracts</NavLink></button>
                                    </li>
                                </ul>
                                <div className="tab-content" id="myTabContent">
                                    <div className="tab-pane fade show active" id="code" role="tabpanel" aria-labelledby="code-tab">
                                        {/* <h4 className="chart-title w afacad mt-3">Contract Source Code Verified (Exact Match)</h4> */}


                                        <div className="section2 mt-3">
                                            <div className="container">
                                                <textarea className="form-control text-muted text-monospace p-3" rows={7} id="extraData"
                                                    value={data?.bytecode}></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="tab-pane fade" id="read-contract" role="tabpanel" aria-labelledby="read-contract-tab">
                                        <div className="d-flex text-box my-3">
                                          
                                           
                                            <div className="container">
                                            <textarea className="form-control text-muted text-monospace p-3" rows={7} id="extraData" 
                                                value={data?.bytecode}></textarea>
                                                {/* <textarea className="form-control text-muted text-monospace p-3" rows={7} id="extraData" defaultValue={" ;lkanjadncdbchibedchbdlicbwdlvbduivbwe           Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."}></textarea> 
                                            </div>
                                      
                                        </div>
                                        
                                    </div> */}


                                    <div className="tab-pane fade" id="read-contract" role="tabpanel" aria-labelledby="read-contract-tab">
                                        <div className="d-flex text-box my-3">
                                            <div className="container">
                                                {/* <textarea 
                            className="form-control text-muted text-monospace p-3" 
                            rows={7} 
                            id="extraData" 
                            value={isOpcodesView ? data?.opcodes : data?.bytecode} // Conditionally show data
                            readOnly
                        /> */}
                                                {isOpcodesView ? (
                                                   
                                                    <textarea
                                                        className="form-control text-muted text-monospace p-3"
                                                        rows={7}
                                                        value={data?.opcodes.join('\n')} 
                                                        readOnly
                                                        id="extraData"
                                                    />
                                                ) : (
                                                   
                                                    <textarea
                                                        className="form-control text-muted text-monospace p-3"
                                                        rows={7}
                                                        id="extraData"
                                                        value={data?.bytecode}
                                                        readOnly
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            {/* {pagination()} */}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
export default Contract;