

import React, { useState, useEffect } from 'react';
import Green from '../../Img/Green.png';
import LineChart from './ChartComponent';
import Skeleton from 'react-loading-skeleton';
import { base_url } from '../../config/Api';
const Marked = () => {
  const [supply, setSupply] = useState(null);
  const [premind, setPremind] = useState(null);
  const [holders, setHolders] = useState(null);
  const [transactions, setTransactions] = useState(null);
  const [rbcPrice, setRbcPrice] = useState(null);
  const [holder, setHolder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [glcPrice, setGlcPrice] = useState(null);

  console.log('holder--==-=--===-',holder);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${base_url}/api/transaction`);
        const data = await response.json();
        const totalTransactions = data.totalTransactions;
        const holder = data.accountHolder;
        const supplyPercentage = data.supplyPercentage;
        const premindPercentage = data.premindPercentage;
        const glcPrice = data.glcPrice;
        setLoading(false);
        setTransactions(totalTransactions);
        setHolder(holder);
        setSupply(supplyPercentage);
        setPremind(premindPercentage);
        setRbcPrice(glcPrice);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchData();

    
    // Fetch GLC price
  //   const fetchPrice = async () => {
  //     try {
  //       // const response = await fetch('https://api.bitnetto.io/api/v3/ticker/price?symbol=GLC');
  //       const response = await fetch('http://192.168.1.80:8080/api/v3/ticker/price?symbol=GLC');
  //       // http://192.168.1.80:8080/api/v3/ticker/price?symbol=GLC
  //       const data = await response.json();
  //       const price = data.price;
  //       setGlcPrice(price);
       
  //     } catch (error) {
  //       console.error('Error fetching GLC price:', error);
  //     }
  //   };

  //   fetchPrice();
  // }, []);

  const fetchPrice = async () => {
    try {
      // const response = await fetch('http://192.168.1.80:8080/api/v3/ticker/price?symbol=GLC');
      const response = await fetch('https://api.bitnetto.io/api/v3/ticker/price?symbol=GLC');
      const result = await response.json();
  
      // Log the entire response object to verify its structure
      // console.log('response', result);
      setGlcPrice(result.price);
  

      // if (result.success === 1) {
      //   // Assuming the GLC price is inside result.data
      //   const glcPrice = result.data;
      //   console.log('GLC Price:', glcPrice);
  
      //   // Ensure setGlcPrice is defined and set the GLC price
      //   setGlcPrice(glcPrice);
      // } else {
      //   console.error('Error: API response indicates failure');
      // }
    } catch (error) {
      console.error('Error fetching GLC price:', error);
    }

  };

  fetchPrice();
}, []);

  return (
    <div className="container">
      <div className="row section1-box">
        <div className="col-lg-4 col-md-4 col_1 mt-3">
          <div className="d-flex icon-box icon-box1">
            <div>
              <div className="icon-box-icon01">
                <img className='box-icon supplylogo' src={Green} alt="logo" />
              </div>
            </div>
            <div className="icon-box-text">
              <p className="icon-box-title bl inter">Supply <span className="g"> (${supply}% Minable) </span></p>
              <p className="icon-box-sub w afacad">3000,000,00 <span className="bl">(${premind}% Premind)</span></p>
            </div>
          </div>
          <div className="d-flex icon-box icon-box2">
            <div>
              <div className="icon-box-icon01">
                <img className='box-icon supplylogo' src={Green} alt="logo" />
              </div>
            </div>
            <div className="icon-box-text">
              <p className="icon-box-title bl inter">Holders</p>
              <p className="icon-box-sub w afacad">
              {loading ? <Skeleton width={50} baseColor="#202020" highlightColor="#444"/> : holder}
                {holder || loading ? holder : <Skeleton width={50} baseColor="#202020" highlightColor="#444"/>}
               
              </p>
              {/* {loading ? <Skeleton  baseColor="#202020" highlightColor="#444"/> :(transactions?.holder)} */}
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 col_1 mt-3 ">
          <div className="d-flex icon-box icon-box1">
            <div>
              <div className="icon-box-icon01">
                <img className='box-icon supplylogo' src={Green} alt="logo" />
              </div>
            </div>
            <div className="icon-box-text">
              <p className="icon-box-title bl inter">Transactions</p>
              <p className="icon-box-sub w afacad">{transactions || loading ? transactions : <Skeleton width={50} baseColor="#202020" highlightColor="#444" />}</p>
              {loading ? <Skeleton  baseColor="#202020" highlightColor="#444"/> :(transactions?.transactions)}
            </div>
          </div>
          <div className="d-flex icon-box icon-box2">
            <div>
              <div className="icon-box-icon01">
                <img className='box-icon supplylogo' src={Green} alt="logo" />
              </div>
            </div>
            <div className="icon-box-text">
              <p className="icon-box-title bl inter">GLC Price</p>
              <p className="icon-box-sub w afacad">
                {/* {glcPrice || loading ? `$ ${glcPrice}` : <Skeleton width={50} baseColor="#202020" highlightColor="#444" />} */}
                { loading ?  <Skeleton width={50} baseColor="#202020" highlightColor="#444" />:`$ ${glcPrice}` }
                </p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 col-chart">
          <h4 class="chart-title w afacad  mb-3"> GLC Transaction History In 14 Days</h4>
          <LineChart />
        </div>
      </div>
    </div>
  );
};

export default Marked;

