// import React from 'react'

// const VerifyPublish = () => {
//   return (
//    <>
//    <h1 className='h4 mb-3'>Verify & Publish Contract Source Code</h1>
//    <p>Source code verification provides transparency for users interacting with smart contracts. By uploading the source code, BscScan will match the compiled code with that on the blockchain. </p>
// <p>A simple and structured interface for verifying smart contracts that fit in a single file.</p>
//    </>
//   )
// }

// export default VerifyPublish


import React, { useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'; // Icons for the arrow
import ReCAPTCHA from 'react-google-recaptcha';
import { useLocation } from 'react-router-dom';
import { base_url } from '../config/Api';
const VerifyPublish = () => {
    const location = useLocation();
    const { formData } = location.state || {}; // Receive the data from the previous page

    const [isOpen, setIsOpen] = useState(true);
    const [isOpens, setIsOpens] = useState(true);
    const [isAdvancedOpen, setIsAdvancedOpen] = useState(true);
    const [isAdvancedOpens, setIsAdvancedOpens] = useState(true);
    const [isLibraryOpen, setIsLibraryOpen] = useState(true);
    const [captchaSuccess, setCaptchaSuccess] = useState(true);
    const [captchaValue, setCaptchaValue] = useState('');
    const [captchaError, setCaptchaError] = useState('');
    const [solidityCode, setSolidityCode] = useState('');
    const handleCodeChange = (e) => {
        setSolidityCode(e.target.value); // Update state as user types
    };
    const toggleOpen = () => {
        setIsOpen(!isOpen); // Toggle the state on click
    };

    const toggleOpens = () => {
        setIsOpens(!isOpens); // Toggle the state on click
    };

    const toggleAdvancedOpen = () => {
        setIsAdvancedOpen(!isAdvancedOpen);
    };

    const toggleAdvancedOpens = () => {
        setIsAdvancedOpens(!isAdvancedOpens);
    };

    const toggleLibraryOpen = () => {
        setIsLibraryOpen(!isLibraryOpen);
    };

    // Handle CAPTCHA Success
    const handleCaptcha = (value) => {
        if (value) {
            setCaptchaSuccess(true);
        }
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        
        
        const postData = {
            contractAddress: formData?.address, // Address from previous page
            compilerType: formData?.compilerType || '', // Compiler type from previous page
            compilerVersion: formData?.compilerVersion || '', // Compiler version from previous page
            licenseType: formData?.licenseType || '', // License type from previous page
            // sourceCode: document.getElementById('extraData').value // Contract code from textarea
            sourceCode: solidityCode,
        };

        try {
            const response = await fetch(`${base_url}/api/verify-contract`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(postData)
            });

            const data = await response.json();

            if (data.success === true) {
                alert('Contract Verified and Published successfully');
            } else {
                alert('Error verifying the contract');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };


    return (
        <>
            <div className="section2 my-5">
                <div className="container">
                    <div className="table-sec ">
                        <div className="row">
                            {/* <div className="col-lg-6 Verify-col"></div> */}
                            <h1 className='w afacad'>Verify & Publish Contract Source Code</h1>
                            <p className='w afacad mt-3"'>Source code verification provides transparency for users interacting with smart contracts. By uploading the source code, BscScan will match the compiled code with that on the blockchain. </p>
                            <p className='w afacad'>A simple and structured interface for verifying smart contracts that fit in a single file.</p>
                            <div className="contract-verification">
                                {/* Heading with Toggle Button */}
                                <div className="header" onClick={toggleOpen} style={styles.header}>
                                    <h2 className='w afacad'>Upload Contract Source Code</h2>
                                    {isOpen ? <FaChevronUp /> : <FaChevronDown />}
                                </div>

                                {/* Collapsible Content */}
                                {isOpen && (
                                    <div className="content" style={styles.content}>
                                        <p className='w afacad'>If the contract compiles correctly at REMIX, it should also compile correctly here.</p>
                                        <p className='w afacad'>We have limited support for verifying contracts created by another contract...</p>

                                        <div>
                                            <label className='w afacad'>Contract Address:</label>
                                            <input type="text"  id="extraData" className='w afacad' value={formData?.address }  style={styles.input} />
                                        </div>

                                        <div>
                                            <label className='w afacad'>Compiler Type:</label>
                                            <input type="text"  id="extraData" className='w afacad' value={formData?.compilerType || ''} readOnly style={styles.input} />
                                        </div>

                                        <div>
                                            <label className='w afacad'>Compiler Version:</label>
                                            <input type="text" id="extraData" className='w afacad' value={formData?.compilerVersion || ''}  readOnly style={styles.input} />
                                        </div>

                                        <div>
                                            <label className='w afacad'>Enter the Solidity Contract Code below</label>
                                            {/* <textarea  className='w afacad'  id="extraData"        style={styles.textarea}></textarea> */}
                                            <textarea
                                            value={solidityCode}
                                            onChange={handleCodeChange}
                                            id="extraData"
                                            style={styles.textarea}
                                        ></textarea>
                                        </div>
                                    </div>
                                )}
                               
                                <div className="contract-config">
                                    {/* Advanced Configuration */}
                                    <div className="advanced-config">
                                        <div className="header" onClick={toggleAdvancedOpen} style={styles.header}>
                                            <h2 className='w afacad'>Advanced Configuration</h2>
                                            {isAdvancedOpen ? <FaChevronUp /> : <FaChevronDown />}
                                        </div>
                                        {isAdvancedOpen && (
                                            <div className="content" style={styles.content}>
                                                <div>
                                                    <label className='w afacad'>Optimization</label>
                                                    <select    id="extraData"style={styles.select}>
                                                        <option   value="no">No</option>
                                                        <option value="yes">Yes</option>
                                                    </select>
                                                </div>

                                                <div>
                                                    <label className='w afacad' >Runs (Optimizer)</label>
                                                    <input type="number" id="extraData" className='w afacad' defaultValue="200" style={styles.input} />
                                                </div>

                                                <div>
                                                    <label className='w afacad'>EVM Version to Target</label>
                                                    <select style={styles.select}  id="extraData">
                                                        <option value="default">Default (compiler defaults)</option>

                                                    </select>
                                                </div>

                                                <div>
                                                    <label className='w afacad' >License Type</label>
                                                    <select style={styles.select}  id="extraData">
                                                        <option value="none">No License (None)</option>

                                                    </select>
                                                </div>
                                            </div>
                                        )}
                                    </div>


                               

                                    <div className="advanced-config">
                                        <div className="header" onClick={toggleAdvancedOpens} style={styles.header}>
                                            <h2 className='w afacad'>Constructor Arguments ABI-encoded</h2>
                                            {isAdvancedOpens ? <FaChevronUp /> : <FaChevronDown />}
                                        </div>
                                        {isAdvancedOpens && (
                                            <div className="content" style={styles.content}>
                                                <label className='w afacad'>Constructor Arguments ABI-encoded</label>
                                                <textarea style={styles.textarea} rows={7} id="extraData"  className="form-control text-muted text-monospace p-3 w afacad" />
                                            </div>
                                        )}
                                    </div>

                                    <div className="contract-library">
                                        <div className="header" onClick={toggleLibraryOpen} style={styles.header}>
                                            <h2 className='w afacad'>Contract Library Address</h2>
                                            {isLibraryOpen ? <FaChevronUp /> : <FaChevronDown />}
                                        </div>
                                        {isLibraryOpen && (
                                            <div className="content" style={styles.content}>
                                                <label className='w afacad'>Library Name</label>
                                                <input type="text"  id="extraData" className='w afacad' placeholder="Enter library name" style={styles.input} />

                                                <label className='w afacad'>Library Contract Address</label>
                                                <input  className='w afacad'  id="extraData" type="text" placeholder="0x..." style={styles.input} />
                                            </div>
                                        )}
                                    </div>


                                    <div className="captcha" style={styles.captcha}>
                                        {/* <ReCAPTCHA
                                            sitekey="6LfyzmgqAAAAAEp_q9PhhOOjb8OlUJMZ_CkMXLHE"
                                            onChange={handleCaptcha}
                                        /> */}
                                        {/* <ReCAPTCHA
          sitekey="6LfyzmgqAAAAAEp_q9PhhOOjb8OlUJMZ_CkMXLHE"
          onChange={handleCaptchaChange}
          onExpired={handleCaptchaError}
          onError={handleCaptchaError}
        />
        {captchaError && <p>{captchaError}</p>} */}
                                    </div>


                                    <div className="submit-buttons" style={styles.buttons}>
                                        {/* {captchaSuccess && <p style={styles.success}>Success!</p>} */}
                                        <button style={styles.button} onClick={handleSubmit}>Verify and Publish</button>
                                        <button style={styles.button}>Reset</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

// Basic inline styling for simplicity
// const styles = {
//   header: {
//     display: 'flex',
//     justifyContent: 'space-between',
//     alignItems: 'center',
//     cursor: 'pointer',
//     backgroundColor: 'black',
//     padding: '10px',
//     border: '1px solid #ccc',
//   },
//   content: {
//     padding: '20px',
//     border: '1px solid #ccc',
//     borderTop: 'none',
//   },
//   input: {
//     width: '100%',
//     padding: '8px',
//     margin: '8px 0',
//     borderRadius: '4px',
//     border: '1px solid #ccc',
//   },
//   textarea: {
//     width: '100%',
//     height: '150px',
//     padding: '8px',
//     borderRadius: '4px',
//     border: '1px solid #ccc',
//   },
// };
const styles = {
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        cursor: 'pointer',
        backgroundColor: 'black',
        padding: '10px',
        border: '1px solid #ccc',
    },
    content: {
        padding: '20px',
        border: '1px solid #ccc',
        borderTop: 'none',
    },
    input: {
        width: '100%',
        padding: '8px',
        margin: '8px 0',
        borderRadius: '4px',
        border: '1px solid #ccc',
    },
    textarea: {
        width: '100%',
        height: '240px',
        padding: '8px',
        borderRadius: '4px',
        border: '1px solid #ccc',
    },
    select: {
        width: '100%',
        padding: '8px',
        margin: '8px 0',
        borderRadius: '4px',
        border: '1px solid #ccc',
    },
    captcha: {
        margin: '20px 0',
    },
    buttons: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    button: {
        padding: '10px 20px',
        backgroundColor: '#007bff',
        color: '#fff',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
    },
    success: {
        color: 'green',
        fontWeight: 'bold',
    },
    section: {
        marginBottom: '20px',
    },
};

export default VerifyPublish;
